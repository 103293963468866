<template>
    <b-modal
        id="modal-detail-document"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <template #modal-header>
            <h5 class="text-white mr-auto">
                {{ `Documento No. ${doc?.documento}` }}
            </h5>

            <b-dropdown
                v-if="!isLoading"
                id="dropdown-more"
                text="Opciones"
                size="md"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
                <template #button-content>
                    <div
                        class="d-flex justify-content-center align-items-center"
                    >
                        <feather
                            type="more-vertical"
                            size="1.2rem"
                            class="text-white mr-2"
                            v-b-popover.hover.top="'Opciones'"
                        ></feather>
                    </div>
                </template>
                <b-dropdown-item-button
                    @click="resendEmailAdquiriente(doc?.id)"
                >
                    Enviar correo adquiriente
                </b-dropdown-item-button>

                <b-dropdown-item-button @click="resendDian(doc?.id)">
                    Reenviar documento DIAN
                </b-dropdown-item-button>

                <b-dropdown-item-button>
                    Consultar DIAN
                </b-dropdown-item-button>
            </b-dropdown>

            <b-spinner
                v-else
                class="text-white mr-2"
                style="width: 1.8rem; height: 1.8rem"
            ></b-spinner>
        </template>

        <b-card
            class="p-1 pt-0 mb-3"
            style="max-height: 600px; min-height: 600px; overflow-y: auto"
        >
            <b-row>
                <b-col cols="12" sm="6">
                    <span class="font-weight-bold">
                        Factura:
                        <span class="font-weight-normal">{{
                            doc?.documento
                        }}</span>
                    </span>

                    <br />

                    <span class="font-weight-bold"
                        >Prefijo:
                        <span class="font-weight-normal">{{
                            doc?.prefijo
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Numeración:
                        <span class="font-weight-normal">{{
                            doc?.numeracion
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Fecha del documento:
                        <span class="font-weight-normal">{{
                            setTimeZoneToDate(
                                doc?.fechadocumento,
                                'DD-MM-YYYY hh:mm:ss a'
                            )
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Registro:
                        <span class="font-weight-normal">{{
                            setTimeZoneToDate(
                                doc?.fecharegistro,
                                'DD-MM-YYYY hh:mm:ss a'
                            )
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Cufe-Cude:
                        <span class="font-weight-normal">{{
                            doc?.cufecude
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Nit facturador:
                        <span class="font-weight-normal">{{
                            doc?.docjson.emp_nit
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Nombre XML:
                        <span class="font-weight-normal">{{
                            doc?.nombrearchivoxml
                        }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Tipo factura:
                        <span class="font-weight-normal"
                            >{{ doc?.tipoDocumento.id }}
                            {{ doc?.tipoDocumento.descripcion }}</span
                        ></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Estado DIAN:
                        <span class="font-weight-normal">{{
                            doc?.isValid ? 'Válido' : 'Inválido'
                        }}</span></span
                    >
                </b-col>

                <b-col cols="12" sm="6">
                    <span class="font-weight-bold"
                        >Fecha envío DIAN:
                        <span class=""
                            >{{
                                setTimeZoneToDate(
                                    doc?.fechaenviodian,
                                    'DD-MM-YYYY hh:mm:ss a'
                                )
                            }}
                        </span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Fecha consulta:
                        <span class=""
                            >{{
                                setTimeZoneToDate(
                                    doc?.fechaconsultadian,
                                    'DD-MM-YYYY hh:mm:ss a'
                                )
                            }}
                        </span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >ZipKey: <span class="">{{ doc?.ZipKey }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Código estado DIAN:
                        <span class="">{{ doc?.statuscode }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Descripción estado DIAN:
                        <span class="">{{ doc?.statusdescription }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Mensaje estado DIAN:
                        <span class="">{{ doc?.statusmessage }}</span></span
                    >

                    <br />

                    <span class="font-weight-bold"
                        >Estado aceptación tácita:
                        <span class="">{{
                            doc?.estadoAceptacionTacita
                        }}</span></span
                    >
                </b-col>
            </b-row>

            <b-row class="mt-4" v-if="doc">
                <b-col cols="12">
                    <div class="accordion" role="tablist">
                        <b-card
                            no-body
                            class="mb-1"
                            v-for="(key, i) in Object.keys(doc?.errores)"
                            :key="i"
                        >
                            <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                            >
                                <!-- v-b-toggle.accordion-1 -->
                                <b-button
                                    block
                                    variant="grey"
                                    disabled
                                    class="d-flex align-items-center justify-content-center"
                                >
                                    <feather
                                        v-if="
                                            doc?.errores[key]
                                                .toLowerCase()
                                                .includes('rechazo')
                                        "
                                        type="alert-circle"
                                        size="1.2rem"
                                        class="text-error mr-2"
                                    ></feather>

                                    <feather
                                        v-else
                                        type="alert-triangle"
                                        size="1.2rem"
                                        class="text-warning mr-2"
                                    ></feather>
                                    {{
                                        doc?.errores[key]
                                            .toLowerCase()
                                            .includes('rechazo')
                                            ? 'Error'
                                            : 'Notificación'
                                    }}
                                    {{
                                        Array.isArray(doc?.errores)
                                            ? Number(key) + 1
                                            : key
                                    }}
                                </b-button>
                            </b-card-header>
                            <b-collapse
                                id="accordion-1"
                                visible
                                :accordion="`my-accordion-${i}`"
                                role="tabpanel"
                                class="collapse"
                            >
                                <b-card-body>
                                    <!-- <b-card-text
                                        >I start opened because
                                        <code>visible</code> is
                                        <code>true</code></b-card-text
                                    > -->
                                    <b-card-text>{{
                                        doc?.errores[key]
                                    }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <div style="float: right">
            <b-btn variant="primary" @click="close">Aceptar</b-btn>
        </div>
    </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { setTimeZoneToDate } from '@/utils/date'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'DetailDocument',
    data: () => ({
        isLoading: false
    }),
    props: { doc: { type: Object, default: () => ({}) } },
    methods: {
        setTimeZoneToDate,
        resendEmailAdquiriente(documentId) {
            this.isLoading = true
            API.POST({
                url: 'procesos-fe/request',
                data: {
                    empresaId: this.getUltimaEmpresa.id,
                    body: {},
                    requestType: 'POST',
                    endpoint: `/api/fe/documentos/resend-mail/${documentId}`
                }
            })
                .then((response) => {
                    // console.log(response)
                    this.isLoading = false
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        console.log(dato)
                        this.$bvModal
                            .msgBoxOk(dato.message, {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje_exception ||
                                    mensaje ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header',
                            noStacking: true
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        resendDian(documentId) {
            console.log(documentId)
            this.isLoading = true
            API.POST({
                url: 'procesos-fe/request',
                data: {
                    empresaId: this.getUltimaEmpresa.id,
                    body: {},
                    requestType: 'POST',
                    endpoint: `/api/fe/documentos/resend-dian/${documentId}`
                }
            })
                .then((response) => {
                    // console.log(response)
                    this.isLoading = false
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        console.log(dato)
                        // this.$bvModal
                        //     .msgBoxOk(dato.message, {
                        //         title: 'Exito!',
                        //         size: 'sm',
                        //         buttonSize: 'sm',
                        //         okVariant: 'success',
                        //         okTitle: 'Aceptar',
                        //         cancelTitle: 'Cancelar',
                        //         footerClass: 'p-2',
                        //         hideHeaderClose: false,
                        //         centered: true,
                        //         headerBgVariant: 'success',
                        //         headerClass: 'modal-header'
                        //     })
                        //     .then(() => {
                        //         // console.log(value);
                        //     })
                        //     .catch((err) => {
                        //         console.log(err)
                        //     })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje_exception ||
                                    mensaje ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header',
                            noStacking: true
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        close() {
            this.$bvModal.hide('modal-detail-document')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 5 }
            return json
        },
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
