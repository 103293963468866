<template>
    <span>
        <b-table
            :fields="fields"
            :items="data"
            :tbody-tr-class="rowClass"
            borderless
            responsive
            head-variant="light"
            class="mb-0 hover-table text-nowrap"
        >
            <template #cell(dataDocument.valor)="data">
                {{ currencyFormat(data.value) }}
                <!-- <span
                    :class="`badge bg-light-${
                        data.value ? 'success' : 'error'
                    }`"
                >
                    {{ data.value ? 'Válido' : 'Inválido' }}
                </span> -->
            </template>
            <template #cell(dataDocument.acciones)="{ index, item }">
                <feather
                    v-if="estadosFacturas[index].check_icon"
                    v-b-tooltip.hover
                    title="Completo"
                    type="check"
                    size="1.2rem"
                    class="text-success mr-2"
                ></feather>

                <!-- !permisos.includes('factura-eventos-accion-recibido') -->
                <feather
                    v-if="
                        (!estadosFacturas[index].check_icon &&
                            !estadosFacturas[index].accion_recivido) ||
                        (!estadosFacturas[index].check_icon &&
                            !permisos.includes(
                                'factura-eventos-accion-recibido'
                            ))
                    "
                    v-b-tooltip.hover
                    title="Recibido de la factura"
                    type="file"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather>

                <!-- @click="showLog('Recibido factura')" -->
                <!-- permisos.includes('factura-eventos-accion-recibido') -->
                <feather
                    v-if="
                        !estadosFacturas[index].check_icon &&
                        estadosFacturas[index].accion_recivido &&
                        permisos.includes('factura-eventos-accion-recibido')
                    "
                    @click="
                        showConfirm(
                            {
                                responsecode: '030',
                                recepciondoc_id: item.id,
                                establecimientocomercio_id: establecimientoId
                            },
                            'Recibido de la factura'
                        )
                    "
                    v-b-tooltip.hover
                    title="Recibido factura"
                    type="file"
                    size="1.2rem"
                    class="text-success mr-2"
                ></feather>

                <!-- !permisos.includes(
                    'factura-eventos-accion-recibido-bien-servicio'
                ) -->
                <feather
                    v-if="
                        (!estadosFacturas[index].check_icon &&
                            !estadosFacturas[index]
                                .accion_recivido_bien_servicio) ||
                        (!estadosFacturas[index].check_icon &&
                            !permisos.includes(
                                'factura-eventos-accion-recibido-bien-servicio'
                            ))
                    "
                    v-b-tooltip.hover
                    title="Recibido del bien o servicio"
                    type="archive"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather>

                <!-- @click="showLog('Recibido del bien o servicio')" -->
                <!-- permisos.includes(
                    'factura-eventos-accion-recibido-bien-servicio'
                ) -->
                <feather
                    v-if="
                        !estadosFacturas[index].check_icon &&
                        estadosFacturas[index].accion_recivido_bien_servicio &&
                        permisos.includes(
                            'factura-eventos-accion-recibido-bien-servicio'
                        )
                    "
                    @click="
                        showConfirm(
                            {
                                responsecode: '032',
                                recepciondoc_id: item.id,
                                establecimientocomercio_id: establecimientoId
                            },
                            'Recibido del bien o servicio'
                        )
                    "
                    v-b-tooltip.hover
                    title="Recibido del bien o servicio"
                    type="archive"
                    size="1.2rem"
                    class="text-success mr-2"
                ></feather>

                <feather
                    v-if="
                        (!estadosFacturas[index]?.check_icon &&
                            !estadosFacturas[index]
                                .accion_aceptacion_expresa) ||
                        (!estadosFacturas[index]?.check_icon &&
                            !permisos.includes(
                                'factura-eventos-accion-acepacion-expresa'
                            ))
                    "
                    v-b-tooltip.hover
                    title="Aceptación expresa"
                    type="file-text"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather>

                <!-- @click="showLog('Acepotacion expresa')" -->
                <feather
                    v-if="
                        !estadosFacturas[index]?.check_icon &&
                        estadosFacturas[index].accion_aceptacion_expresa &&
                        permisos.includes(
                            'factura-eventos-accion-acepacion-expresa'
                        )
                    "
                    @click="
                        showConfirm(
                            {
                                responsecode: '033',
                                recepciondoc_id: item.id,
                                establecimientocomercio_id: establecimientoId
                            },
                            'Aceptación expresa'
                        )
                    "
                    v-b-tooltip.hover
                    title="Aceptación expresa"
                    type="file-text"
                    size="1.2rem"
                    class="text-success mr-2"
                ></feather>

                <feather
                    v-if="
                        (!estadosFacturas[index]?.check_icon &&
                            !estadosFacturas[index].accion_reclamo) ||
                        (!estadosFacturas[index]?.check_icon &&
                            !permisos.includes(
                                'factura-eventos-accion-reclamo'
                            ))
                    "
                    v-b-tooltip.hover
                    title="Reclamo factura"
                    type="x-circle"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather>
                <!-- @click="showLog('Reclamo factura')" -->
                <!-- @click="
                    sendEvent({
                        responsecode: '031',
                        recepciondoc_id: item.id,
                        establecimientocomercio_id: establecimientoId
                    })
                " -->
                <feather
                    v-if="
                        !estadosFacturas[index]?.check_icon &&
                        estadosFacturas[index].accion_reclamo &&
                        permisos.includes('factura-eventos-accion-reclamo')
                    "
                    @click="
                        $bvModal.show('observacion-modal'),
                            (selectedDocumento = item)
                    "
                    v-b-tooltip.hover
                    title="Reclamo factura"
                    type="x-circle"
                    size="1.2rem"
                    class="text-error mr-2"
                ></feather>

                <!-- @click="verFacturaDIAN(item.dataDocument.cufe)" -->
                <feather
                    v-if="permisos.includes('factura-eventos-ver-factura-dian')"
                    @click="verFacturaDIAN(item.dataDocument.cufe)"
                    v-b-tooltip.hover
                    title="Ver factura DIAN"
                    type="external-link"
                    size="1.2rem"
                    class="text-warning mr-2"
                ></feather>

                <feather
                    v-if="
                        !permisos.includes('factura-eventos-ver-factura-dian')
                    "
                    v-b-tooltip.hover
                    title="Ver factura DIAN"
                    type="external-link"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather>

                <!-- @click="showLog('Ver detalles')" -->
                <feather
                    v-if="!permisos.includes('factura-eventos-ver-detalles')"
                    v-b-tooltip.hover
                    title="Ver detalles"
                    type="eye"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather>

                <feather
                    v-else
                    @click="showDetailDocument(item)"
                    v-b-tooltip.hover
                    title="Ver detalles"
                    type="eye"
                    size="1.2rem"
                    class="text-success mr-2"
                ></feather>

                <!-- <feather
                    v-if="permisos.includes('factura_eventos_download_zip')"
                    v-b-tooltip.hover
                    title="Descargar"
                    @click="
                        downloadFile({
                            fileData: item.id,
                            name: item.documento,
                            ext: 'zip'
                        })
                    "
                    type="download"
                    size="1.2rem"
                    class="text-primary mr-2"
                ></feather> -->

                <!-- <feather
                    v-if="!permisos.includes('factura_eventos_download_zip')"
                    v-b-tooltip.hover
                    title="Descargar"
                    type="download"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather> -->

                <!-- <feather
                    v-if="permisos.includes('factura_eventos_reenviar_email')"
                    @click="forwardEmail(item.id)"
                    v-b-tooltip.hover
                    title="Reenviar"
                    type="repeat"
                    size="1.2rem"
                    class="text-primary mr-2"
                ></feather>

                <feather
                    v-if="!permisos.includes('factura_eventos_reenviar_email')"
                    v-b-tooltip.hover
                    title="Reenviar"
                    type="repeat"
                    size="1.2rem"
                    class="text-secondary mr-2"
                ></feather> -->
            </template>
        </b-table>
        <detailEvento :doc="selectedDocument" />
        <observacion
            @selectedObservation="
                (observacion) => {
                    sendEvent({
                        responsecode: '031',
                        recepciondoc_id: selectedDocumento.id,
                        establecimientocomercio_id: establecimientoId,
                        observacion
                    })
                    selectedDocumento = null
                }
            "
        />
    </span>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters } from 'vuex'
import observacion from '@/components/shared/modal-observacion/observacion.vue'
import { currencyFormat /* downloadFile */ } from '@/utils/others'
import detailEvento from '@/components/shared/detailEvento/detailEvento'

const { API } = services

export default {
    name: 'EventosTable',
    components: { observacion, detailEvento },
    props: {
        data: { type: Array, default: () => [] },
        estadosFacturas: { type: Array, default: () => [] },
        establecimientoId: { type: String, default: '' }
    },
    data: () => ({
        fields: [
            { key: 'dataDocument.InvoiceTypeCode', label: 'Tipo documento' },
            { key: 'dataDocument.numero_documento', label: 'Número' },
            { key: 'dataDocument.fecha', label: 'Fecha' },
            { key: 'dataDocument.vencimiento', label: 'Vencimiento' },
            {
                key: 'dataDocument.datosempresa.idDocumentoEmp',
                label: 'Nit tercero'
            },
            {
                key: 'dataDocument.datosempresa.rasonsocialEmp',
                label: 'Tercero'
            },
            { key: 'dataDocument.valor', label: 'Valor' },
            { key: 'dataDocument.acciones', label: 'Acciones' }
        ],
        selectedDocument: null
    }),
    methods: {
        currencyFormat,
        // downloadFile,
        showDetailDocument(document) {
            this.selectedDocument = document
            this.$bvModal.show('modal-detail-event')
        },
        showConfirm(DIANEvent, eventName) {
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de realizar "${eventName}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.sendEvent(DIANEvent)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // forwardEmail(factura_id) {
        //     API.POST({
        //         url: 'procesos-fe/request',
        //         data: {
        //             empresaId: this.getUltimaEmpresa.id,
        //             body: {},
        //             requestType: 'POST',
        //             endpoint: `/api/fe/documentos/resend-mail/${factura_id}`
        //         }
        //     })
        //         .then((response) => {
        //             // console.log(response)
        //             const {
        //                 data: {
        //                     // dato,
        //                     status: { status, mensaje, mensaje_exception }
        //                 }
        //             } = response
        //             if (status === 'ok') {
        //                 // this.$emit('isLoading', false)
        //                 // this.$emit('reloadTable')
        //                 this.$bvModal
        //                     .msgBoxOk(mensaje || 'Transación exitosa!', {
        //                         title: 'Exito!',
        //                         size: 'sm',
        //                         buttonSize: 'sm',
        //                         okVariant: 'success',
        //                         okTitle: 'Aceptar',
        //                         cancelTitle: 'Cancelar',
        //                         footerClass: 'p-2',
        //                         hideHeaderClose: false,
        //                         centered: true,
        //                         headerBgVariant: 'success',
        //                         headerClass: 'modal-header'
        //                     })
        //                     .then(() => {
        //                         // console.log(value);
        //                     })
        //                     .catch((err) => {
        //                         console.log(err)
        //                     })
        //             } else {
        //                 //     this.$emit('isLoading', false)
        //                 this.$bvModal
        //                     .msgBoxOk(
        //                         mensaje_exception ||
        //                             mensaje ||
        //                             'Ha ocurrido un error!',
        //                         {
        //                             title: 'Error!',
        //                             size: 'sm',
        //                             buttonSize: 'sm',
        //                             okVariant: 'error',
        //                             okTitle: 'Aceptar',
        //                             cancelTitle: 'Cancelar',
        //                             footerClass: 'p-2',
        //                             hideHeaderClose: false,
        //                             centered: true,
        //                             headerBgVariant: 'error',
        //                             headerClass: 'modal-header'
        //                         }
        //                     )
        //                     .then(() => {
        //                         // console.log(value);
        //                     })
        //                     .catch((err) => {
        //                         console.log(err)
        //                     })
        //             }
        //         })
        //         .catch((error) => {
        //             const { message } = error
        //             this.$bvModal
        //                 .msgBoxOk(message || 'Ha ocurrido un error!', {
        //                     title: 'Error!',
        //                     size: 'sm',
        //                     buttonSize: 'sm',
        //                     okVariant: 'error',
        //                     okTitle: 'Aceptar',
        //                     cancelTitle: 'Cancelar',
        //                     footerClass: 'p-2',
        //                     hideHeaderClose: false,
        //                     centered: true,
        //                     headerBgVariant: 'error',
        //                     headerClass: 'modal-header'
        //                 })
        //                 .then(() => {
        //                     // console.log(value);
        //                 })
        //                 .catch((err) => {
        //                     console.log(err)
        //                 })
        //         })
        // },
        sendEvent(DIANEvent) {
            // console.log(DIANEvent)
            this.$emit('isLoading', true)
            API.POST({
                url: 'procesos-fe/request',
                data: {
                    empresaId: this.getUltimaEmpresa.id,
                    body: {
                        ...DIANEvent
                    },
                    requestType: 'POST',
                    endpoint: '/api/documents-reception/notification'
                }
            })
                .then((response) => {
                    // console.log(response)
                    const {
                        data: {
                            // dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.$emit('isLoading', false)
                        this.$emit('reloadTable')
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Transación exitosa!', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$emit('isLoading', false)
                        this.$bvModal
                            .msgBoxOk(
                                mensaje_exception ||
                                    mensaje ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        verFacturaDIAN(cufe) {
            const url = `https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=${cufe}`
            window.open(url, '_blank').focus()
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        }
    },
    computed: {
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', ['getPermisos']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>
