<template>
    <b-modal
        id="modal-detail-event"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <template #modal-header>
            <h5 class="text-white mr-auto">
                {{ `Documento No. ${doc?.dataDocument.numero_documento}` }}
            </h5>

            <b-dropdown
                v-if="!isLoading"
                id="dropdown-more"
                text="Opciones"
                size="md"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
                <template #button-content>
                    <div
                        class="d-flex justify-content-center align-items-center"
                    >
                        <feather
                            type="more-vertical"
                            size="1.2rem"
                            class="text-white mr-2"
                            v-b-popover.hover.top="'Opciones'"
                        ></feather>
                    </div>
                </template>
                <b-dropdown-item-button
                    :disabled="doc?.actionReceivedStatus.code === 'DEFAULT'"
                    style="cursor: pointer"
                    @click="
                        resendEmailEvent({
                            documentId: doc.id,
                            eventCode: '030'
                        })
                    "
                >
                    Enviar copia por email del recibido de la factura
                </b-dropdown-item-button>

                <b-dropdown-item-button
                    :disabled="doc?.actionReceivedWellStatus.code === 'DEFAULT'"
                    style="cursor: pointer"
                    @click="
                        resendEmailEvent({
                            documentId: doc.id,
                            eventCode: '032'
                        })
                    "
                >
                    Enviar copia por email del recibido del bien o servicio
                </b-dropdown-item-button>

                <b-dropdown-item-button
                    :disabled="
                        doc?.actionReceivedExpressStatus.code === 'DEFAULT'
                    "
                    style="cursor: pointer"
                    @click="
                        resendEmailEvent({
                            documentId: doc.id,
                            eventCode: '033'
                        })
                    "
                >
                    Enviar copia por email de la aceptación expresa
                </b-dropdown-item-button>

                <b-dropdown-item-button
                    :disabled="doc?.actionClaimStatus.code === 'DEFAULT'"
                    style="cursor: pointer"
                    @click="
                        resendEmailEvent({
                            documentId: doc.id,
                            eventCode: '031'
                        })
                    "
                >
                    Enviar copia por email del reclamo de la factura
                </b-dropdown-item-button>
            </b-dropdown>

            <b-spinner
                v-else
                class="text-white mr-2"
                style="width: 1.8rem; height: 1.8rem"
            ></b-spinner>
        </template>

        <b-card
            class="p-1 pt-0 mb-3"
            style="max-height: 300px; min-height: 300px; overflow-y: auto"
        >
            <b-tabs
                active-nav-item-class="font-weight-bold text-white bg-primary"
                active-tab-class="text-black"
                content-class="mt-3"
                align="center"
            >
                <b-tab title="Recibido factura" active>
                    <span
                        >Estado del evento:
                        <span
                            :class="`text-${getStatusColor(
                                doc?.actionReceivedStatus.code
                            )}`"
                        >
                            {{ doc?.actionReceivedStatus.description }}
                        </span></span
                    >
                </b-tab>
                <b-tab title="Recibo del bien o servicio">
                    <span
                        >Estado del evento:
                        <span
                            :class="`text-${getStatusColor(
                                doc?.actionReceivedWellStatus.code
                            )}`"
                        >
                            {{ doc?.actionReceivedWellStatus.description }}
                        </span></span
                    >
                </b-tab>
                <b-tab title="Aceptación expresa">
                    <span
                        >Estado del evento:
                        <span
                            :class="`text-${getStatusColor(
                                doc?.actionReceivedExpressStatus.code
                            )}`"
                        >
                            {{ doc?.actionReceivedExpressStatus.description }}
                        </span></span
                    >
                </b-tab>
                <b-tab title="Reclamo factura">
                    <span
                        >Estado del evento:
                        <span
                            :class="`text-${getStatusColor(
                                doc?.actionClaimStatus.code
                            )}`"
                        >
                            {{ doc?.actionClaimStatus.description }}
                        </span></span
                    >
                </b-tab>
            </b-tabs>
        </b-card>
        <div style="float: right">
            <b-btn variant="primary" @click="close">Aceptar</b-btn>
        </div>
    </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import { setTimeZoneToDate } from '@/utils/date'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'DetailEvento',
    data: () => ({
        isLoading: false
    }),
    props: { doc: { type: Object, default: () => ({}) } },
    methods: {
        getStatusColor(code) {
            if (code === 'DEFAULT') {
                return 'secondary'
            }

            if (code === 'AUTORIZADO') {
                return 'success'
            }

            if (code === 'RECHAZADO') {
                return 'error'
            }
        },
        resendEmailEvent({ documentId, eventCode }) {
            this.isLoading = true
            API.POST({
                url: 'procesos-fe/request',
                data: {
                    empresaId: this.getUltimaEmpresa.id,
                    body: {
                        receptionDocId: documentId,
                        responseCode: eventCode
                    },
                    requestType: 'POST',
                    endpoint: '/api/documents-reception/resend-mail'
                }
            })
                .then((response) => {
                    this.isLoading = false
                    const {
                        data: {
                            dato: {
                                status: {
                                    mensaje: m,
                                    mensaje_exception: me,
                                    status: s
                                }
                            },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (s === 'ok') {
                        this.$bvModal
                            .msgBoxOk(me || m, {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        console.log({
                            status,
                            mensaje,
                            mensaje_exception
                        })
                        this.$bvModal
                            .msgBoxOk(me || m || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header',
                            noStacking: true
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        close() {
            this.$bvModal.hide('modal-detail-event')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 5 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 50%;
    max-width: 50%;
}

.dialog2 {
    min-height: auto;
}
</style>
