var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{staticClass:"mb-0 hover-table text-nowrap",attrs:{"fields":_vm.fields,"items":_vm.data,"tbody-tr-class":_vm.rowClass,"borderless":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(dataDocument.isvalid)",fn:function(data){return [_c('span',{class:`badge bg-light-${
                    data.value ? 'success' : 'error'
                }`},[_vm._v(" "+_vm._s(data.value ? 'Válido' : 'Inválido')+" ")])]}},{key:"cell(acciones)",fn:function({ item }){return [(_vm.permisos.includes('factura-eventos-ver-detalles'))?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"title":"Ver detalles","type":"eye","size":"1.2rem"},on:{"click":function($event){return _vm.showDetailDocument(item)}}}):_vm._e(),(!_vm.permisos.includes('factura-eventos-ver-detalles'))?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 text-secondary",attrs:{"title":"Ver detalles","type":"eye","size":"1.2rem"}}):_vm._e(),(
                    _vm.permisos.includes('factura-eventos-download-pdf') &&
                    !_vm.isLoadingPdf
                )?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"title":"Descargar pdf","type":"file-text","size":"1.2rem"},on:{"click":function($event){return _vm.getFileDocument({
                        documentId: item.id,
                        documentName: item.documento,
                        key: 'pdf'
                    })}}}):_vm._e(),(
                    !_vm.permisos.includes('factura-eventos-download-pdf') &&
                    !_vm.isLoadingPdf
                )?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 text-secondary",attrs:{"title":"Descargar pdf","type":"file-text","size":"1.2rem"}}):_vm._e(),(_vm.isLoadingPdf)?_c('b-spinner',{staticClass:"mr-2",staticStyle:{"height":"1.2rem","width":"1.2rem"}}):_vm._e(),(
                    _vm.permisos.includes('factura-eventos-download-zip') &&
                    !_vm.isLoadingZip
                )?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"title":"Descargar zip","type":"folder","size":"1.2rem"},on:{"click":function($event){return _vm.getFileDocument({
                        documentId: item.id,
                        documentName: item.documento,
                        key: 'zip'
                    })}}}):_vm._e(),(_vm.isLoadingZip)?_c('b-spinner',{staticClass:"mr-2",staticStyle:{"height":"1.2rem","width":"1.2rem"}}):_vm._e(),(!_vm.permisos.includes('factura-eventos-download-zip'))?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 text-secondary",attrs:{"title":"Descargar zip","type":"folder","size":"1.2rem"}}):_vm._e(),(
                    _vm.permisos.includes('factura-eventos-aceptacion-tacita')
                )?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"title":"Aceptación tácita","type":"check-square","size":"1.2rem"}}):_vm._e(),(
                    !_vm.permisos.includes('factura-eventos-aceptacion-tacita')
                )?_c('feather',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 text-secondary",attrs:{"title":"Aceptación tácita","type":"check-square","size":"1.2rem"}}):_vm._e()]}}])}),_c('DetailDocument',{attrs:{"doc":_vm.selectedDocument}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }