<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center mb-4">
                <div class="ml-auto">
                    <!-- <div
                        class="d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"
                    >
                        <input
                            type="file"
                            ref="excelInput"
                            id="excelInput"
                            style="display: none"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="changeFile"
                        /> -->
                    <!-- <b-button
                            variant="primary"
                            size="md"
                            class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                            @click="addRegister"
                            v-b-popover.hover.top="'Presione alt+a o option+a'"
                            :disabled="!permisos.includes('tercero-crear')"
                        >
                            <feather
                                type="plus"
                                size="1rem"
                                class="mr-2 blue-active-text"
                            ></feather>
                            Cargar factura
                        </b-button> -->
                    <!-- <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes('tercero-query-export') &&
                                !permisos.includes(
                                    'tercero-query-export-and-email'
                                ) &&
                                !permisos.includes('tercero-crud-export') &&
                                !permisos.includes('tercero-crud-import')
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes('tercero-query-export')
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'tercero-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item-button
                                @click="getImportFormat(moduleName)"
                                :disabled="
                                    !permisos.includes('tercero-crud-export')
                                "
                                >Plantilla para importación de
                                tabla</b-dropdown-item-button
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item-button
                                @click="$refs.excelInput.click()"
                                :disabled="
                                    !permisos.includes('tercero-crud-import')
                                "
                                >Cargar Plantilla para importación de
                                tabla</b-dropdown-item-button
                            >
                        </b-dropdown> -->
                    <!-- </div> -->
                </div>
            </div>
        </b-col>
        <b-col cols="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <b-row class="mt-2 mb-4">
                    <b-col cols="12" sm="4">
                        <b-form-group>
                            <label for="nitTercero">Empresa</label>
                            <b-form-input
                                id="nitTercero"
                                type="number"
                                v-model="$v.query.nitTercero.$model"
                                disabled
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <!-- v-if="query.tipo_documento_id === '96'" -->
                    <b-col cols="12" sm="4">
                        <b-form-group>
                            <label for="establecimiento_id">Sede</label>
                            <jautocomplete
                                id="establecimiento_id"
                                :items="establecimientos"
                                item-value="id"
                                item-text="description"
                                open-on-focus
                                v-model="$v.query.establecimiento_id.$model"
                                :disabled="
                                    establecimientos.length === 0 || isLoading
                                "
                                @change="
                                    () => {
                                        if (file) {
                                            uploadFactura()
                                        }
                                    }
                                "
                                :state="validateState('establecimiento_id')"
                            >
                            </jautocomplete>
                            <div
                                class="invalid-field mt-1"
                                v-if="$v.query.establecimiento_id.$error"
                            >
                                Campo requerido
                            </div>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="4">
                        <b-form-group>
                            <label for="file">ZIP de la factura</label>
                            <b-form-file
                                id="file"
                                accept="application/zip"
                                :state="validateStateFile()"
                                v-model="$v.file.$model"
                                :disabled="
                                    isLoading ||
                                    !permisos.includes(
                                        'factura-eventos-subir-zip'
                                    )
                                "
                                @change="uploadFactura"
                            ></b-form-file>
                            <!-- @change="uploadFactura" -->
                            <b-form-invalid-feedback v-if="!$v.file.required">
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="12">
                        <b-form-group>
                            <label for="tipo_documento_id"
                                >Tipo documento</label
                            >
                            <jautocomplete
                                id="tipo_documento_id"
                                :items="tiposDocumento"
                                item-value="id"
                                item-text="descripcion"
                                open-on-focus
                                v-model="$v.query.tipo_documento_id.$model"
                                :state="validateState('tipo_documento_id')"
                                :disabled="isLoading"
                                @change="
                                    () => {
                                        documentos = []
                                    }
                                "
                            >
                            </jautocomplete>
                            <div
                                class="invalid-field mt-1"
                                v-if="$v.query.tipo_documento_id.$error"
                            >
                                Campo requerido
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="12" sm="4">
                        <b-form-group>
                            <label for="fecha_inicio">Fecha Inicio</label>
                            <b-input-group>
                                <b-form-input
                                    id="fecha_inicio"
                                    v-model="$v.query.fecha_inicio.$model"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    :state="validateState('fecha_inicio')"
                                    autocomplete="null"
                                    :disabled="isLoading"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker
                                        v-model="$v.query.fecha_inicio.$model"
                                        button-only
                                        right
                                        locale="en-US"
                                        :state="validateState('fecha_inicio')"
                                        aria-controls="fecha_inicio"
                                        :disabled="isLoading"
                                    ></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-invalid-feedback
                                :state="$v.query.fecha_inicio.formatDate"
                            >
                                Formato de fecha inválido
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                :state="$v.query.fecha_inicio.compareDates"
                            >
                                La fecha inicial no puede ser mayor a la fecha
                                final
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="4">
                        <b-form-group>
                            <label for="fecha_inicio">Fecha Final</label>
                            <b-input-group>
                                <b-form-input
                                    id="fecha_final"
                                    v-model="$v.query.fecha_final.$model"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    :state="validateState('fecha_final')"
                                    autocomplete="null"
                                    :disabled="isLoading"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker
                                        v-model="$v.query.fecha_final.$model"
                                        button-only
                                        right
                                        locale="en-US"
                                        :state="validateState('fecha_final')"
                                        aria-controls="fecha_final"
                                        :disabled="isLoading"
                                    ></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-invalid-feedback
                                :state="$v.query.fecha_final.formatDate"
                            >
                                Formato de fecha inválido
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                :state="$v.query.fecha_final.compareDates"
                            >
                                La fecha inicial no puede ser mayor a la fecha
                                final
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="4">
                        <label for="numero_factura">Número factura</label>
                        <b-form-input
                            id="numero_factura"
                            type="text"
                            placeholder="Ej: FV005"
                            v-model="$v.query.numero_factura.$model"
                            :disabled="isLoading"
                        ></b-form-input>
                    </b-col>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="12" sm="4">
                        <label for="nit_tercero">Nit tercero</label>
                        <b-form-input
                            id="nit_tercero"
                            type="number"
                            placeholder="Ej: 12343463"
                            v-model="$v.query.nit_tercero.$model"
                            :disabled="isLoading"
                        ></b-form-input>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="4"
                        v-if="query.tipo_documento_id === '96'"
                    >
                        <b-form-group>
                            <label for="evento_id">Evento</label>
                            <jautocomplete
                                id="evento_id"
                                :items="eventos"
                                item-value="codigo"
                                item-text="descripcion"
                                open-on-focus
                                v-model="$v.query.evento_id.$model"
                                :disabled="isLoading"
                            >
                            </jautocomplete>
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="4"
                        v-if="query.tipo_documento_id === '96'"
                    >
                        <b-form-group>
                            <label for="estado_id">Estados</label>
                            <jautocomplete
                                id="estado_id"
                                :items="estados"
                                item-value="codigo"
                                item-text="descripcion"
                                open-on-focus
                                v-model="$v.query.estado_id.$model"
                                :disabled="
                                    $v.query.evento_id.$model === null ||
                                    isLoading
                                "
                            >
                            </jautocomplete>
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="8"
                        v-if="query.tipo_documento_id !== '96'"
                    >
                        <b-form-group>
                            <label for="estado_dian_id">Estado DIAN</label>
                            <jautocomplete
                                id="estado_dian_id"
                                :items="estadosDIAN"
                                item-value="id"
                                item-text="label"
                                open-on-focus
                                v-model="$v.query.estado_dian_id.$model"
                                :disabled="isLoading"
                            >
                            </jautocomplete>
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- <div class="float-right mb-5">
                    <b-button
                        variant="primary"
                        size="md"
                        class="d-flex align-items-center"
                        @click="getDocumentosElectronicos(pagination)"
                        :disabled="isLoading"
                    >
                        <feather
                            :type="isLoading ? 'loader' : 'search'"
                            size="1rem"
                            class="text-blue-active mr-2"
                        ></feather>
                        <span>{{ isLoading ? 'Cargando...' : 'Consultar' }}</span>
                    </b-button>
                </div> -->

                <b-button
                    variant="primary"
                    size="md"
                    block
                    class="d-flex align-items-center justify-content-center mb-5"
                    @click="getDocumentosElectronicos(pagination)"
                    :disabled="
                        isLoading ||
                        !permisos.includes('consultar-documentos-fe')
                    "
                >
                    <feather
                        :type="isLoading ? 'loader' : 'search'"
                        size="1rem"
                        class="text-blue-active mr-2"
                    ></feather>
                    <span>{{ isLoading ? 'Cargando...' : 'Consultar' }}</span>
                </b-button>

                <div
                    class="float-right mb-2"
                    v-if="query.tipo_documento_id == '96'"
                >
                    <b-button
                        variant="primary"
                        size="md"
                        class="d-flex align-items-center"
                        :disabled="isLoading"
                    >
                        <feather
                            :type="isLoading ? 'loader' : 'table'"
                            size="1rem"
                            class="text-blue-active mr-2"
                        ></feather>
                        <span>{{
                            isLoading ? 'Cargando...' : 'Exportar'
                        }}</span>
                    </b-button>
                </div>

                <div class="table-responsive mt-3" v-hotkey="keymap">
                    <eventosTable
                        v-if="$v.query.tipo_documento_id.$model === '96'"
                        :data="documentos"
                        :estadosFacturas="estadosFacturas"
                        :establecimientoId="$v.query.establecimiento_id.$model"
                        @reloadTable="getDocumentosElectronicos(pagination)"
                        @isLoading="(v) => (isLoading = v)"
                    />

                    <documentosTable v-else :data="documentos" />
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            size="sm"
                            @change="
                                getDocumentosElectronicos({
                                    ...pagination,
                                    perPage: $event
                                })
                            "
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            align="fill"
                            class="my-0"
                            @input="
                                getDocumentosElectronicos({
                                    ...pagination,
                                    currentPage: $event
                                })
                            "
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
            <selectFieldsReport
                :modulo="moduleName"
                :format="selectedFormat"
                :fields="
                    fieldsAvailables[
                        selectedFormat ? selectedFormat.toLowerCase() : 'excel'
                    ]
                "
                @close="selectedFormat = null"
            />
            <emailModal
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
            />
        </b-col>
        <!-- <b-col cols="12">
            <div class="d-sm-flex mt-4 justify-content-end align-items-center"> -->
        <!-- <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('tercero-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Cargar factura
                </b-button> -->
        <!-- <b-dropdown
                    id="dropdown-divider"
                    text="Opciones"
                    size="md"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="
                        !permisos.includes('tercero-query-export') &&
                        !permisos.includes('tercero-query-export-and-email') &&
                        !permisos.includes('tercero-crud-export') &&
                        !permisos.includes('tercero-crud-import')
                    "
                >
                    <template #button-content>
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <feather
                                type="more-horizontal"
                                size="1.5rem"
                                class="text-secondary"
                            ></feather>
                        </div>
                    </template>
                    <b-dropdown-item-button
                        v-for="(format, index) in formats"
                        :key="index"
                        @click="verifyFields(format.ext)"
                        :disabled="!permisos.includes('tercero-query-export')"
                    >
                        {{ format.name }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        @click="$bvModal.show('email-modal')"
                        :disabled="
                            !permisos.includes('tercero-query-export-and-email')
                        "
                        >Enviar informe por Correo</b-dropdown-item-button
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                        @click="getImportFormat(moduleName)"
                        :disabled="!permisos.includes('tercero-crud-export')"
                        >Plantilla para importación de
                        tabla</b-dropdown-item-button
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                        @click="$refs.excelInput.click()"
                        :disabled="!permisos.includes('tercero-crud-import')"
                        >Cargar Plantilla para importación de
                        tabla</b-dropdown-item-button
                    >
                </b-dropdown>
            </div>
        </b-col> -->
        <progressBar :percentage="percentage" />
        <crearTerceroModalVue @newTercero="setNewTercero" />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import selectFieldsReport from '@/components/shared/select-fields-report/select-fields-report'
import emailModal from '@/components/shared/email/email'
// import { firstToUpperString } from '@/utils/others'
import {
    removeListenerCommands,
    addListenerCommands,
    getBase64File,
    onUploadProgress,
    firstToUpperString
} from '@/utils/others'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { filterTerceros } from '@/utils/filters'
import { validationMixin } from 'vuelidate'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import crearTerceroModalVue from '../../../general/generales/terceros/crearTerceroModal.vue'
import { compareDates, dateNow } from '@/utils/date'
import * as XLSX from 'xlsx'
import eventosTable from './components/eventosTable.vue'
import documentosTable from './components/documentosTable.vue'
import progressBar from '@/components/shared/progress-bar/progressBar.vue'

const { API } = services

export default {
    name: 'DocumentosElectronicos',
    components: {
        selectFieldsReport,
        emailModal,
        jautocomplete,
        crearTerceroModalVue,
        eventosTable,
        documentosTable,
        progressBar
    },
    mixins: [validationMixin],
    mounted() {
        addListenerCommands(this)
        this.getEmpresaEmails()
        // this.getAvailableFields()
        this.getTipoDocumentoElectronico()
        this.getEventos()
        this.getEstados()
        this.getEstadosDIAN()
        this.query.nitTercero = this.getUltimaEmpresa.numero_documento
        this.getEstablecimientosByNit(this.getUltimaEmpresa.numero_documento)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        query: {
            tipo_documento_id: '01',
            nitTercero: null,
            establecimiento_id: null,
            fecha_inicio: dateNow(-2),
            fecha_final: dateNow(),
            numero_factura: '',
            nit_tercero: '',
            evento_id: null,
            estado_id: null,
            estado_dian_id: null
        },
        percentage: 0,
        file: null,
        isLoading: false,
        estadosFacturas: [],
        eventos: [],
        estados: [],
        estadosDIAN: [],
        tiposDocumento: [],
        documentos: [],
        establecimientos: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [2, 10, 20, 30, 40, 50, 100]
        },
        fieldsAvailables: {},
        configEmail: null,
        selectedFormat: null,
        moduleName: 'documentosElectronicos',
        formats: [
            { id: 1, name: 'Generar Informe Excel', ext: 'EXCEL' },
            { id: 2, name: 'Generar Informe Pdf', ext: 'PDF' }
        ]
    }),
    validations: {
        file: {
            required
        },
        query: {
            tipo_documento_id: {
                required
            },
            nitTercero: {
                required
            },
            establecimiento_id: {
                requiredIf: requiredIf(function () {
                    const required = this.file !== null
                    return required
                })
            },
            fecha_inicio: {
                required,
                compareDates: (d, a) => {
                    if (a.fecha_final) {
                        return compareDates(d, a.fecha_final)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            fecha_final: {
                required,
                compareDates: (d, a) => {
                    if (a.fecha_inicio) {
                        return compareDates(a.fecha_inicio, d)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            numero_factura: {},
            nit_tercero: {},
            evento_id: {},
            estado_id: {},
            estado_dian_id: {}
        }
    },
    methods: {
        filterTerceros,
        showLog(data) {
            console.log(data)
        },
        firstToUpperString,
        uploadFactura() {
            setTimeout(() => {
                const validTercero = !this.$v.query.nitTercero.$invalid
                const validSede = !this.$v.query.establecimiento_id.$invalid

                const valid = validTercero && validSede

                if (valid) {
                    this.isLoading = true
                    setTimeout(async () => {
                        const base64File = await getBase64File(this.file)

                        const body = {
                            file: base64File,
                            establecimientoId: this.query.establecimiento_id
                        }

                        API.POST({
                            url: 'procesos-fe/request',
                            data: {
                                empresaId: this.getUltimaEmpresa.id,
                                body: {
                                    ...body
                                },
                                requestType: 'POST',
                                endpoint: '/api/documents-reception/upload'
                            },
                            onUploadProgress: (loadProgress) => {
                                const { percentage } =
                                    onUploadProgress(loadProgress)

                                this.percentage = percentage

                                this.$bvModal.show('progress-bar-modal')
                            }
                        })
                            .then((response) => {
                                // console.log(response)
                                this.isLoading = false
                                const {
                                    data: {
                                        dato,
                                        status: {
                                            status,
                                            mensaje,
                                            mensaje_exception
                                        }
                                    }
                                } = response
                                if (status === 'ok') {
                                    // console.log(dato)
                                    this.$bvModal.hide('progress-bar-modal')
                                    this.$bvModal
                                        .msgBoxOk(dato.message, {
                                            title: 'Exito!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'success',
                                            headerClass: 'modal-header'
                                        })
                                        .then(() => {
                                            // console.log(value);
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                    setTimeout(() => {
                                        this.file = null
                                    }, 10)
                                    setTimeout(() => {
                                        this.$v.file.$reset()
                                    }, 10)
                                    this.getDocumentosElectronicos()
                                } else {
                                    setTimeout(() => {
                                        this.file = null
                                    }, 10)
                                    setTimeout(() => {
                                        this.$v.file.$reset()
                                    }, 10)
                                    this.$bvModal.hide('progress-bar-modal')
                                    this.$bvModal
                                        .msgBoxOk(
                                            mensaje_exception ||
                                                mensaje ||
                                                'Ha ocurrido un error!',
                                            {
                                                title: 'Error!',
                                                size: 'sm',
                                                buttonSize: 'sm',
                                                okVariant: 'error',
                                                okTitle: 'Aceptar',
                                                cancelTitle: 'Cancelar',
                                                footerClass: 'p-2',
                                                hideHeaderClose: false,
                                                centered: true,
                                                headerBgVariant: 'error',
                                                headerClass: 'modal-header'
                                            }
                                        )
                                        .then(() => {
                                            // console.log(value);
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }
                            })
                            .catch((error) => {
                                this.$bvModal.hide('progress-bar-modal')
                                setTimeout(() => {
                                    this.file = null
                                }, 10)
                                setTimeout(() => {
                                    this.$v.file.$reset()
                                }, 10)
                                this.isLoading = false
                                const { message } = error
                                this.$bvModal
                                    .msgBoxOk(
                                        message || 'Ha ocurrido un error!',
                                        {
                                            title: 'Error!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'error',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'error',
                                            headerClass: 'modal-header',
                                            noStacking: true
                                        }
                                    )
                                    .then(() => {
                                        // console.log(value);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            })
                    }, 10)
                } else {
                    this.$v.query.nitTercero.$touch()
                    this.$v.query.establecimiento_id.$touch()
                }
            }, 10)
        },
        setUltimaSedeAPI(sede) {
            API.POST({
                url: 'general/selec-ultima/sede/crud',
                data: {
                    p_datajson: {
                        sede_id: sede.id,
                        usuario_id: this.getUserData.id
                    },
                    p_opc: 'UPDATE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.setUltimaSede(sede)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        setNewEstablecimientoComercio(establecimiento) {
            this.establecimientos.push(establecimiento)
            this.$v.query.establecimiento_id.$model = establecimiento.id
        },
        setNewTercero(tercero) {
            this.terceros.push(tercero)
            this.$v.query.nitTercero = tercero.id
        },
        searchData() {
            this.getDocumentosElectronicos(this.search)
        },
        showConfirm({
            id,
            razon_social,
            nombre1,
            apellido1,
            tipo_documento_id
        }) {
            const name =
                tipo_documento_id === '31'
                    ? razon_social
                    : `${nombre1} ${apellido1}`
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar al tercero "${name}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteTercero(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteTercero(id) {
            API.POST({
                url: 'general/tercero/crud',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'DELETE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.getDocumentosElectronicos()
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Eliminado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        asignarEstadosFacturas(facturas) {
            this.estadosFacturas = []
            facturas.forEach((factura) => {
                const estado = {
                    accion_recivido: true,
                    accion_reclamo: true,
                    accion_recivido_bien_servicio: true,
                    accion_aceptacion_expresa: true,
                    alert_icon: true,
                    details: true,
                    check_icon: true
                }
                const recibido = factura.actionReceivedStatus.code
                const reclamo = factura.actionClaimStatus.code
                const recibido_bien_servicio =
                    factura.actionReceivedWellStatus.code
                const aceptacion_expresa =
                    factura.actionReceivedExpressStatus.code

                if (recibido === 'AUTORIZADO') {
                    estado.accion_recivido = false
                }

                if (
                    recibido_bien_servicio === 'AUTORIZADO' ||
                    recibido !== 'AUTORIZADO'
                ) {
                    estado.accion_recivido_bien_servicio = false
                }

                if (
                    aceptacion_expresa === 'AUTORIZADO' ||
                    recibido_bien_servicio !== 'AUTORIZADO' ||
                    reclamo === 'AUTORIZADO'
                ) {
                    estado.accion_aceptacion_expresa = false
                }

                if (
                    recibido !== 'AUTORIZADO' ||
                    recibido_bien_servicio !== 'AUTORIZADO' ||
                    aceptacion_expresa === 'AUTORIZADO' ||
                    reclamo === 'AUTORIZADO'
                ) {
                    estado.accion_reclamo = false
                }

                if (
                    (recibido !== 'RECHAZADO' &&
                        recibido_bien_servicio !== 'RECHAZADO') ||
                    aceptacion_expresa === 'AUTORIZADO' ||
                    reclamo === 'AUTORIZADO'
                ) {
                    estado.alert_icon = false
                }

                if (
                    recibido === 'DEFAULT' &&
                    recibido_bien_servicio === 'DEFAULT' &&
                    aceptacion_expresa === 'DEFAULT' &&
                    reclamo === 'DEFAULT'
                ) {
                    estado.details = false
                }

                if (
                    aceptacion_expresa !== 'AUTORIZADO' &&
                    reclamo !== 'AUTORIZADO'
                ) {
                    estado.check_icon = false
                }

                this.estadosFacturas.push(estado)

                // console.log(this.estadosFacturas)
            })
        },
        getDocumentosElectronicos({ currentPage, perPage }) {
            if (this.permisos.includes('consultar-documentos-fe')) {
                const valid = !this.$v.query.$invalid

                if (valid) {
                    this.isLoading = true
                    const {
                        fecha_final,
                        fecha_inicio,
                        establecimiento_id,
                        nitTercero,
                        tipo_documento_id,
                        estado_dian_id,
                        estado_id,
                        numero_factura,
                        evento_id
                    } = this.query

                    API.POST({
                        url: 'procesos-fe/request',
                        data: {
                            empresaId: this.getUltimaEmpresa.id,
                            body: {
                                offset: (currentPage - 1) * perPage,
                                limit: perPage,
                                fechaFinal: new Date(fecha_final).toISOString(),
                                fechaInicial: new Date(
                                    fecha_inicio
                                ).toISOString(),
                                establecimientoId: establecimiento_id,
                                tipoDocumentoElectronico: tipo_documento_id,
                                estadoDian: estado_dian_id,
                                estado: estado_id,
                                numFactura: numero_factura,
                                nitTercero,
                                evento: evento_id
                            },
                            requestType: 'POST',
                            endpoint: '/api/documents-reception'
                        }
                    })
                        .then((response) => {
                            this.isLoading = false
                            const {
                                data: {
                                    dato,
                                    status: {
                                        status,
                                        mensaje,
                                        mensaje_exception
                                    }
                                }
                            } = response
                            // console.log(response.data)
                            if (status === 'ok') {
                                if (tipo_documento_id === '96') {
                                    this.asignarEstadosFacturas(dato.data)
                                }
                                this.documentos = dato.data
                                this.pagination.totalRows =
                                    dato.paginationNumber * perPage
                            } else {
                                this.$bvModal
                                    .msgBoxOk(
                                        mensaje ||
                                            mensaje_exception ||
                                            'Ha ocurrido un error!',
                                        {
                                            title: 'Error!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'error',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'error',
                                            headerClass: 'modal-header'
                                        }
                                    )
                                    .then(() => {
                                        // console.log(value);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            }
                        })
                        .catch((error) => {
                            this.isLoading = false
                            const { message } = error
                            this.$bvModal
                                .msgBoxOk(message || 'Ha ocurrido un error!', {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header',
                                    noStacking: true
                                })
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        })
                    // this.asignarEstadosFacturas(this.documentos)
                } else {
                    this.$v.query.$touch()
                }
            } else {
                this.$bvModal
                    .msgBoxOk(
                        'Usted no tiene permisos para cargar los documentos electronicos',
                        {
                            title: 'Advertencia!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'warning',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'warning',
                            headerClass: 'modal-header',
                            noStacking: true
                        }
                    )
                    .then(() => {
                        // console.log(value);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        getEstablecimientosByNit(nit) {
            if (nit) {
                API.POST({
                    url: 'procesos-fe/request',
                    data: {
                        empresaId: this.getUltimaEmpresa.id,
                        body: {},
                        requestType: 'GET',
                        endpoint: `/api/establecimiento-comercio/terceros/${nit}`
                    }
                })
                    .then((response) => {
                        // console.log(response)
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.establecimientos = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje_exception ||
                                        mensaje ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        verifyFields(ext) {
            this.selectedFormat = ext
            this.$bvModal.show('modal-fields-report')
        },
        getAvailableFields() {
            if (this.permisos.includes('tercero-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        // console.log(response.data)
                        if (status === 'ok') {
                            // console.log(dato);
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getTipoDocumentoElectronico() {
            API.GET({
                url: 'procesos-fe/tipo-documento'
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { mensaje, mensaje_exception, status }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.tiposDocumento = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getEventos() {
            API.GET({
                url: 'procesos-fe/evento'
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { mensaje, mensaje_exception, status }
                        }
                    } = response
                    if (status === 'ok') {
                        this.eventos = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getEstados() {
            API.GET({
                url: 'procesos-fe/estado-documento'
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { mensaje, mensaje_exception, status }
                        }
                    } = response
                    if (status === 'ok') {
                        this.estados = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getEstadosDIAN() {
            API.GET({
                url: 'procesos-fe/estado-dian'
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { mensaje, mensaje_exception, status }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.estadosDIAN = dato.map((item) => ({
                            label: item.id,
                            value: item.value
                        }))
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        changeFile(evt) {
            if (evt) {
                const file = evt.target.files[0]
                const reader = new FileReader()

                reader.onloadend = (e) => {
                    const data = e.target.result
                    const workbook = XLSX.read(data, { type: 'binary' })
                    const sheet = workbook.SheetNames[0]
                    const XL_ROW_OBJECT = XLSX.utils.sheet_to_json(
                        workbook.Sheets[sheet]
                    )
                    if (XL_ROW_OBJECT.length > 0) {
                        API.POST({
                            url: 'report/form/import',
                            data: {
                                p_datajson: {
                                    datos: [...XL_ROW_OBJECT],
                                    empresa_id: this.getUltimaEmpresa.id
                                },
                                p_opc: this.moduleName.toUpperCase(),
                                p_auditoriajson: this.auditoriajson
                            }
                        })
                            .then((response) => {
                                const {
                                    data: {
                                        dato,
                                        status: {
                                            status,
                                            mensaje,
                                            mensaje_exception
                                        }
                                    }
                                } = response
                                this.$refs.excelInput.value = null
                                if (
                                    status === 'ok' &&
                                    dato.some((d) => d.status.status !== 'err')
                                ) {
                                    this.getDocumentosElectronicos()
                                } else {
                                    const mesaje_d = dato
                                        .map((d) => d.status.mensaje)
                                        .join(', ')
                                    this.$bvModal
                                        .msgBoxOk(
                                            dato.some(
                                                (d) => d.status.status === 'err'
                                            )
                                                ? mesaje_d
                                                : mensaje ||
                                                      mensaje_exception ||
                                                      'Ha ocurrido un error!',
                                            {
                                                title: 'Error!',
                                                size: 'sm',
                                                buttonSize: 'sm',
                                                okVariant: 'error',
                                                okTitle: 'Aceptar',
                                                cancelTitle: 'Cancelar',
                                                footerClass: 'p-2',
                                                hideHeaderClose: false,
                                                centered: true,
                                                headerBgVariant: 'error',
                                                headerClass: 'modal-header'
                                            }
                                        )
                                        .then(() => {
                                            // console.log(value);
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }
                            })
                            .catch((error) => {
                                const { message } = error
                                this.$bvModal
                                    .msgBoxOk(
                                        message || 'Ha ocurrido un error!',
                                        {
                                            title: 'Error!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'error',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'error',
                                            headerClass: 'modal-header'
                                        }
                                    )
                                    .then(() => {
                                        // console.log(value);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            })
                    }
                    // console.log(workbook)
                }

                reader.readAsBinaryString(file)
            }
        },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        addRegister() {
            this.$router.push(
                '/home/electronica/consulta-documentos/documentos-electronicos/nuevo-acuse'
            )
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.query[key]
            return $dirty ? !$error : null
        },
        validateStateFile() {
            const { $dirty, $error } = this.$v.file
            return $dirty ? !$error : null
        },
        textFieldTercero(tercero) {
            return tercero.tipo_documento_id === '31'
                ? tercero.razon_social
                : `${tercero.nombre1} ${tercero.apellido1}`
        },
        getEstablecimientosComercio(terceroId) {
            console.log(terceroId)
            API.POST({
                url: 'general/establecimiento-comercio/query',
                data: {
                    p_datajson: {
                        nitTercero: terceroId,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_TERCERO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        console.log(dato)
                        this.establecimientos = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        ...mapActions(['getImportFormat'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 1 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
