var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-detail-event","header-bg-variant":"primary","hide-footer":"","no-close-on-backdrop":"","body-class":`${_vm.Theme === 'dark' && 'dark'} dialog2`,"dialog-class":"dialog","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"text-white mr-auto"},[_vm._v(" "+_vm._s(`Documento No. ${_vm.doc?.dataDocument.numero_documento}`)+" ")]),(!_vm.isLoading)?_c('b-dropdown',{attrs:{"id":"dropdown-more","text":"Opciones","size":"md","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Opciones'),expression:"'Opciones'",modifiers:{"hover":true,"top":true}}],staticClass:"text-white mr-2",attrs:{"type":"more-vertical","size":"1.2rem"}})],1)]},proxy:true}],null,false,2406168218)},[_c('b-dropdown-item-button',{staticStyle:{"cursor":"pointer"},attrs:{"disabled":_vm.doc?.actionReceivedStatus.code === 'DEFAULT'},on:{"click":function($event){return _vm.resendEmailEvent({
                        documentId: _vm.doc.id,
                        eventCode: '030'
                    })}}},[_vm._v(" Enviar copia por email del recibido de la factura ")]),_c('b-dropdown-item-button',{staticStyle:{"cursor":"pointer"},attrs:{"disabled":_vm.doc?.actionReceivedWellStatus.code === 'DEFAULT'},on:{"click":function($event){return _vm.resendEmailEvent({
                        documentId: _vm.doc.id,
                        eventCode: '032'
                    })}}},[_vm._v(" Enviar copia por email del recibido del bien o servicio ")]),_c('b-dropdown-item-button',{staticStyle:{"cursor":"pointer"},attrs:{"disabled":_vm.doc?.actionReceivedExpressStatus.code === 'DEFAULT'},on:{"click":function($event){return _vm.resendEmailEvent({
                        documentId: _vm.doc.id,
                        eventCode: '033'
                    })}}},[_vm._v(" Enviar copia por email de la aceptación expresa ")]),_c('b-dropdown-item-button',{staticStyle:{"cursor":"pointer"},attrs:{"disabled":_vm.doc?.actionClaimStatus.code === 'DEFAULT'},on:{"click":function($event){return _vm.resendEmailEvent({
                        documentId: _vm.doc.id,
                        eventCode: '031'
                    })}}},[_vm._v(" Enviar copia por email del reclamo de la factura ")])],1):_c('b-spinner',{staticClass:"text-white mr-2",staticStyle:{"width":"1.8rem","height":"1.8rem"}})]},proxy:true}])},[_c('b-card',{staticClass:"p-1 pt-0 mb-3",staticStyle:{"max-height":"300px","min-height":"300px","overflow-y":"auto"}},[_c('b-tabs',{attrs:{"active-nav-item-class":"font-weight-bold text-white bg-primary","active-tab-class":"text-black","content-class":"mt-3","align":"center"}},[_c('b-tab',{attrs:{"title":"Recibido factura","active":""}},[_c('span',[_vm._v("Estado del evento: "),_c('span',{class:`text-${_vm.getStatusColor(
                            _vm.doc?.actionReceivedStatus.code
                        )}`},[_vm._v(" "+_vm._s(_vm.doc?.actionReceivedStatus.description)+" ")])])]),_c('b-tab',{attrs:{"title":"Recibo del bien o servicio"}},[_c('span',[_vm._v("Estado del evento: "),_c('span',{class:`text-${_vm.getStatusColor(
                            _vm.doc?.actionReceivedWellStatus.code
                        )}`},[_vm._v(" "+_vm._s(_vm.doc?.actionReceivedWellStatus.description)+" ")])])]),_c('b-tab',{attrs:{"title":"Aceptación expresa"}},[_c('span',[_vm._v("Estado del evento: "),_c('span',{class:`text-${_vm.getStatusColor(
                            _vm.doc?.actionReceivedExpressStatus.code
                        )}`},[_vm._v(" "+_vm._s(_vm.doc?.actionReceivedExpressStatus.description)+" ")])])]),_c('b-tab',{attrs:{"title":"Reclamo factura"}},[_c('span',[_vm._v("Estado del evento: "),_c('span',{class:`text-${_vm.getStatusColor(
                            _vm.doc?.actionClaimStatus.code
                        )}`},[_vm._v(" "+_vm._s(_vm.doc?.actionClaimStatus.description)+" ")])])])],1)],1),_c('div',{staticStyle:{"float":"right"}},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.close}},[_vm._v("Aceptar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }