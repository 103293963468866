<template>
    <b-modal
        id="observacion-modal"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <template #modal-header>
            <h5 class="text-white">Seleccione una observación</h5>
        </template>
        <b-card class="p-3">
            <b-form-group>
                <jautocomplete
                    placeholder="Seleccione una observación"
                    id="observacion"
                    :items="observaciones"
                    item-value="id"
                    item-text="descripcion"
                    open-on-focus
                    v-model="observacion"
                    :disabled="isLoading"
                >
                </jautocomplete>
            </b-form-group>
        </b-card>
        <div class="float-right">
            <b-button
                variant="secondary"
                :disabled="isLoading"
                size="md"
                class="mr-4"
                @click="close"
            >
                Cancelar
            </b-button>

            <b-button
                variant="primary"
                :disabled="isLoading"
                size="md"
                @click="setObservacion(observacion)"
            >
                <!-- @click="prepareReport" -->
                <span
                    v-if="isLoading"
                    class="d-flex align-items-center justify-content-center"
                >
                    <b-spinner small></b-spinner>
                    <span class="ml-2">Generando...</span>
                </span>
                <span v-else>Aceptar</span>
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'ObservacionModal',
    components: { jautocomplete },
    data: () => ({
        isLoading: false,
        observaciones: [],
        observacion: null
    }),
    mounted() {
        this.getObservaciones()
    },
    methods: {
        getObservaciones() {
            API.POST({
                url: 'procesos-fe/request',
                data: {
                    empresaId: this.getUltimaEmpresa.id,
                    body: {},
                    requestType: 'GET',
                    endpoint: '/api/documents-reception/rechazo-observaciones'
                }
            })
                .then((response) => {
                    // console.log(response)
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.observaciones = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje_exception ||
                                    mensaje ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        setObservacion(observation_id) {
            const observacion = this.observaciones.find(
                (o) => o.id === observation_id
            )

            this.$emit('selectedObservation', observacion ? observacion : {})

            this.close()
        },
        close() {
            this.observacion = null
            this.$bvModal.hide('observacion-modal')
        }
    },
    computed: {
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 40%;
}

.dialog2 {
    min-height: auto;
}
</style>
