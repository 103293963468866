<template>
    <div>
        <b-table
            :fields="fields"
            :items="data"
            :tbody-tr-class="rowClass"
            borderless
            responsive
            head-variant="light"
            class="mb-0 hover-table text-nowrap"
        >
            <template #cell(dataDocument.isvalid)="data">
                <span
                    :class="`badge bg-light-${
                        data.value ? 'success' : 'error'
                    }`"
                >
                    {{ data.value ? 'Válido' : 'Inválido' }}
                </span>
            </template>
            <template #cell(acciones)="{ item }">
                <feather
                    v-b-tooltip.hover
                    v-if="permisos.includes('factura-eventos-ver-detalles')"
                    title="Ver detalles"
                    type="eye"
                    size="1.2rem"
                    class="mr-2"
                    @click="showDetailDocument(item)"
                ></feather>

                <feather
                    v-b-tooltip.hover
                    v-if="!permisos.includes('factura-eventos-ver-detalles')"
                    title="Ver detalles"
                    type="eye"
                    size="1.2rem"
                    class="mr-2 text-secondary"
                ></feather>
                <!-- @click="showLog('Recibido factura')" -->
                <!-- downloadFile({
                    fileData: item.pdfBase64,
                    name: item.documento,
                    ext: 'pdf',
                    mimeType: 'data:application/pdf;base64,'
                }) -->
                <feather
                    v-if="
                        permisos.includes('factura-eventos-download-pdf') &&
                        !isLoadingPdf
                    "
                    v-b-tooltip.hover
                    @click="
                        getFileDocument({
                            documentId: item.id,
                            documentName: item.documento,
                            key: 'pdf'
                        })
                    "
                    title="Descargar pdf"
                    type="file-text"
                    size="1.2rem"
                    class="mr-2"
                ></feather>

                <feather
                    v-if="
                        !permisos.includes('factura-eventos-download-pdf') &&
                        !isLoadingPdf
                    "
                    v-b-tooltip.hover
                    title="Descargar pdf"
                    type="file-text"
                    size="1.2rem"
                    class="mr-2 text-secondary"
                ></feather>

                <b-spinner
                    v-if="isLoadingPdf"
                    class="mr-2"
                    style="height: 1.2rem; width: 1.2rem"
                ></b-spinner>

                <!-- @click="showLog('Recibido del bien o servicio')" -->
                <!-- downloadFile({
                    fileData: item.zipBase64,
                    name: item.documento,
                    ext: 'zip'
                }) -->
                <feather
                    v-b-tooltip.hover
                    v-if="
                        permisos.includes('factura-eventos-download-zip') &&
                        !isLoadingZip
                    "
                    title="Descargar zip"
                    @click="
                        getFileDocument({
                            documentId: item.id,
                            documentName: item.documento,
                            key: 'zip'
                        })
                    "
                    type="folder"
                    size="1.2rem"
                    class="mr-2"
                ></feather>

                <b-spinner
                    v-if="isLoadingZip"
                    class="mr-2"
                    style="height: 1.2rem; width: 1.2rem"
                ></b-spinner>

                <feather
                    v-b-tooltip.hover
                    v-if="!permisos.includes('factura-eventos-download-zip')"
                    title="Descargar zip"
                    type="folder"
                    size="1.2rem"
                    class="mr-2 text-secondary"
                ></feather>

                <!-- @click="showLog('Acepotacion expresa')" -->
                <feather
                    v-if="
                        permisos.includes('factura-eventos-aceptacion-tacita')
                    "
                    v-b-tooltip.hover
                    title="Aceptación tácita"
                    type="check-square"
                    size="1.2rem"
                    class="mr-2"
                ></feather>

                <feather
                    v-if="
                        !permisos.includes('factura-eventos-aceptacion-tacita')
                    "
                    v-b-tooltip.hover
                    title="Aceptación tácita"
                    type="check-square"
                    size="1.2rem"
                    class="mr-2 text-secondary"
                ></feather>

                <!-- @click="showLog('Reclamo factura')" -->
                <!-- <feather
                v-b-tooltip.hover
                title="Rechazar factura"
                type="x-circle"
                size="1.2rem"
                class="text-error mr-2"
            ></feather> -->
            </template>
        </b-table>
        <DetailDocument :doc="selectedDocument" />
    </div>
</template>
<script>
import services from '@/boot/axios'
import { downloadFile } from '@/utils/others.js'
import DetailDocument from '@/components/shared/detailDocument/detailDocument'
import { mapGetters } from 'vuex'

const { API } = services

export default {
    name: 'DocumentosTable',
    props: {
        data: { type: Array, default: () => [] }
    },
    components: { DetailDocument },
    data: () => ({
        isLoadingPdf: false,
        isLoadingZip: false,
        selectedDocument: null,
        fields: [
            { key: 'documento', label: 'Factura' },
            { key: 'docjson.cli_nit', label: 'Nit adquiriente' },
            { key: 'fechadocumento', label: 'Fecha' },
            { key: 'isvalid', label: 'Estado DIAN' },
            { key: 'estadoAceptacionTacita', label: 'Estado acp tácita' },
            { key: 'documentosoftcontable', label: 'Factura soft contable' },
            { key: 'tipoDocumento.descripcion', label: 'Tipo Documento' },
            { key: 'acciones', label: 'Acciones' }
        ]
    }),
    methods: {
        downloadFile,
        setLoading(key, value) {
            key === 'pdf'
                ? (this.isLoadingPdf = value)
                : (this.isLoadingZip = value)
        },
        getFileDocument({ documentId, key, documentName }) {
            this.setLoading(key, true)
            API.POST({
                url: 'procesos-fe/request',
                data: {
                    empresaId: this.getUltimaEmpresa.id,
                    body: {},
                    requestType: 'GET',
                    endpoint: `/api/fe/documentos/get-file/${documentId}?format=${key}`
                }
            })
                .then((response) => {
                    this.setLoading(key, false)
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        let base64File = null

                        if (key === 'pdf') {
                            const { pdfBase64 } = dato
                            base64File = pdfBase64
                        } else {
                            base64File = dato
                        }

                        let mimeType =
                            key === 'pdf'
                                ? 'data:application/pdf;base64,'
                                : 'data:application/zip;base64,'

                        downloadFile({
                            fileData: base64File,
                            name: documentName,
                            ext: key,
                            mimeType
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje_exception ||
                                    mensaje ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    this.setLoading(key, false)
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        showLog(data) {
            console.log(data)
        },
        showDetailDocument(document) {
            this.selectedDocument = document
            this.$bvModal.show('modal-detail-document')
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        }
    },
    computed: {
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', ['getPermisos']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>
